<template>
  <div id="dash">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <a href="javascript:void(0)">-<span v-html="'&nbsp'"></span>帮助</a>
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="newsList">
              <div class="newsItem">
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/help1"
                      >房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项</router-link
                    >
                  </div>
                  <div class="newsText">
                    <router-link to="/help1"
                      >如果你是新房装修、二手房装修，不知道从何着手，如果你在房子装修过程中遇到厨房、卫生间装修不知道需要注意哪些事项，那么小编提供的这篇房屋装修注意事项，会给你不少启发。今天来看下水电安装注意事项。</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="newsItem">
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/help2">租房也可变Dream House</router-link>
                  </div>
                  <div class="newsText">
                    <router-link to="/help2"
                      >要租一间自己满意的房真的很难，你想自己花钱改造一下，房东连打颗钉子都不行？今天我们就来聊聊那些租房人的最爱，不敲砖不打洞，也能把租房变成自己温馨的Dream
                      house。</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="newsItem">
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/help3"
                      >房东利益如何最大化之合理装修</router-link
                    >
                  </div>
                  <div class="newsText">
                    <router-link to="/help3"
                      >让自己的每一间房都为房东创造最大的收益，是每一位房东的愿望，可是往往事与愿违，总是感觉哪里出了问题，就是找不到根本所在，房东利器在这里为大家抛砖引玉，谈一下房东利益如何最大化之合理装修供大家参考。</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="newsItem">
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/help4"
                      >自建房的房屋租赁税计算</router-link
                    >
                  </div>
                  <div class="newsText">
                    <router-link to="/help4">
                      房屋租赁管理中的自建房的房屋租赁税计算，什么是房屋租赁税呢?有很多地区的居民们都是在自家土地上盖房子，除了自家居住外，可运用房屋出租软件将剩余的房间管理出租，过上收租生活，当然这也是需要缴纳一定的房屋租赁税的，自建房的房屋租赁税的计算是怎样的呢?</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/news1">
                  <img
                    src="../../assets/images/information/news1.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    住房租赁市场回暖 监管强化助力住房租赁市场规范发展
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news2">
                  <img
                    src="../../assets/images/information/news2.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    六省市整治住房租赁市场 住房租赁立法亟须按下“快进键”
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news3">
                  <img src="../../assets/images/information/new3.jpeg" alt="" />
                  <div class="msg">房屋租赁经纪行业制度</div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news4">
                  <img src="../../assets/images/information/new4.jpeg" alt="" />
                  <div class="msg">中央加快住房租赁市场立法</div>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#dash {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/help/帮助背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        .el-pagination {
          padding: 50px 5px;
          text-align: center;
        }
        .newsList {
          color: #000;
          min-height: 300px;
          .newsItem {
            height: 132px;
            overflow: hidden;
            padding: 25px 5px;
            border-bottom: 1px solid #d2d2d2;
            display: flex;

            .newsArticle {
              padding-left: 15px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              text-align: left;
              width: calc(100% - 80px);
              .newsTitle {
                font-size: 22px;
                margin-bottom: 12px;
                line-height: 30px;
                :hover {
                  color: #00b0ff;
                }
                a {
                  color: #000;
                }
              }
              .newsText {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                font-size: 14px;
                line-height: 24px;
                a {
                  color: rgba(91, 95, 103, 0.8);
                }
              }
            }
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
